import React from 'react';
import { Link, graphql } from 'gatsby';
import { Layout } from '../components/Layout';

const BlogPost = ({ data }) => {
	const { markdownRemark: post } = data

	return <Layout
		title={ post.frontmatter.title }
		description={ post.frontmatter.description ?? '' }
	>
		<div className="lg:w-2/4 text-lg md:mx-auto">
			<h1>{ post.frontmatter.title }</h1>
			<div>
				Posted on { post.frontmatter.date }
			</div>
			<div
				className="my-8 bg-white bg-opacity-5 rounded-md p-4 -mx-4"
				dangerouslySetInnerHTML={{ __html: post.html }}
			/>
		</div>
	</Layout>;
}

export default BlogPost;

export const pageQuery = graphql`
	query BlogPostByID($id: String!) {
		markdownRemark(id: { eq: $id }) {
			id
			html
			frontmatter {
				date(formatString: "yyyy-MM-DD")
				title
				description
			}
		}
	}
`;
